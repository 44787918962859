<template>
  <div>
    <div
      v-if="!bWithoutFooter()"
      :class="isMobile ? 'footer-table-mobile' : 'footer-table'"
    >
      <div class="content-footer-pagination-dropdown">
        <div class="content-page-per-row">
          <p class="footer-text-pagination">Filas por página:</p>
        </div>
        <div class="content-dropdown-pagination">
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="footer-table-dropdown"
                text
                v-bind="attrs"
                v-on="on"
              >
                {{ iItemsPerPage }}
                <v-icon>mdi-chevron-down</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item-group v-model="selectedItem" color="primary">
                <v-list-item @click="changeTable(30)">
                  <v-list-item-title class="footer-text-list"
                    >30</v-list-item-title
                  >
                </v-list-item>
                <v-list-item @click="changeTable(60)">
                  <v-list-item-title class="footer-text-list"
                    >60</v-list-item-title
                  >
                </v-list-item>
                <v-list-item @click="changeTable(90)">
                  <v-list-item-title class="footer-text-list"
                    >90</v-list-item-title
                  >
                </v-list-item>
                <v-list-item @click="changeTable(120)">
                  <v-list-item-title class="footer-text-list"
                    >120</v-list-item-title
                  >
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-menu>
        </div>
      </div>
      <div class="content-footer-pagination-arrows">
        <div class="content-current-page-num-page">
          <p class="footer-text-pagination">
            {{ iCurrentPage }}-{{ numPages }} de {{ totalItems }}
          </p>
        </div>
        <div class="content-pagination-arrow">
          <v-btn icon class="footer-table-buttons" @click="firstPage">
            <v-icon>mdi-chevron-double-left</v-icon>
          </v-btn>
          <v-btn icon class="footer-table-buttons" @click="backPage">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          <v-btn icon class="footer-table-buttons" @click="nextPage">
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
          <v-btn icon class="footer-table-buttons" @click="lastPage">
            <v-icon>mdi-chevron-double-right</v-icon>
          </v-btn>
        </div>
      </div>
    </div>
    <div v-show="bAddRawMaterials" class="content-add-raw-material-oc-detail">
      <div
        v-show="!isMobile"
        class="content-input-search-raw-material"
        id="ContentInputAddRawMaterialOCDetail"
      >
        <v-select
          v-if="bAdminPermissions"
          v-model="sValueRawMaterial"
          :items="itemSearchRawMaterial"
          item-text="sName"
          item-value="sRawMaterialId"
          :loading="bLoadingBtnAddRawMaterial"
          :disabled="bLoadingBtnAddRawMaterial || bBlockAddRawMaterial"
          :readonly="bLoadingBtnAddRawMaterial"
          class="global-select"
          color="var(--primary-color-border-input)"
          background-color="var(--primary-color-menu)"
          outlined
          clearable
        >
          <template slot="label">
            <span
              >Buscador de materia prima <span class="important-data"></span
            ></span>
          </template>
        </v-select>
      </div>

      <div
        v-show="!isMobile"
        id="ContentBtnAddRawMaterialOCDetail"
        class="content-btn-add-raw-material-oc-detail content-btn-primary"
      >
        <v-btn
          v-if="bAdminPermissions"
          class="global-btn-primary btn-add-raw-material-oc-detail"
          @click="add()"
          :loading="bLoadingBtnAddRawMaterial"
          :disabled="bAddRawMaterial || bBlockAddRawMaterial"
        >
          Añadir nueva
        </v-btn>
      </div>

      <v-expand-transition v-if="isMobile">
        <v-select
          v-if="bAdminPermissions"
          v-show="bActive"
          v-model="sValueRawMaterial"
          :items="itemSearchRawMaterial"
          item-text="sName"
          item-value="sRawMaterialId"
          :loading="bLoadingBtnAddRawMaterial || bBlockAddRawMaterial"
          :disabled="bLoadingBtnAddRawMaterial"
          :readonly="bLoadingBtnAddRawMaterial"
          class="global-select"
          color="var(--primary-color-border-input)"
          background-color="var(--primary-color-menu)"
          outlined
          clearable
        >
          <template slot="label">
            <span
              >Buscador de materia prima <span class="important-data"></span
            ></span>
          </template>
        </v-select>
      </v-expand-transition>
      <div class="content-btn-primary">
        <v-btn
          v-if="bAdminPermissions"
          v-show="isMobile"
          class="global-btn-primary btn-add-raw-material-oc-detail"
          :loading="bLoadingBtnAddRawMaterial"
          @click="add()"
          :disabled="bAddRawMaterial || bBlockAddRawMaterial"
        >
          Añadir nueva
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    numPages: Number,
    totalItems: Number,
    bChangeSelectedItem: Boolean,
    oItem: Object,
    bAdminPermissions: Boolean,
    bCloseBtnRawMaterial: Boolean,
    bBlockAddRawMaterial: Boolean,
    bInitialCurrentPage: Boolean,
  },
  data() {
    return {
      isMobile: false,
      expand: false,
      bActive: false,
      selectedItem: 0,
      iCurrentPage: 1,
      iPageNumber: 1,
      iItemsPerPage: 30,
      totalView: 0,
      aOCDetail: [, "OPPendingDetail", "AddOperation"],
      aAddRawMaterials: ["OPPendingDetail", "AddOperation"],
      sValueRawMaterial: null,
      itemSearchRawMaterial: [],
      bLoadingBtnAddRawMaterial: false,
      bAddRawMaterial: false,
    };
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  beforeMount() {
    this.setFillFieldFilter();
    if (this.bAddRawMaterials) {
      this.getRawMaterials();
    }
  },
  methods: {
    setFillFieldFilter() {
      this.iCurrentPage = this.iCurrentPageGlobal;
      this.iItemsPerPage = this.iItemsPerPageGlobal;
    },

    //#region RESIZE PAGE

    handleResize() {
      if (window.innerWidth > 599.99) {
        this.isMobile = false;
      } else {
        this.isMobile = true;
      }
    },
    //#endregion RESIZE PAGE

    //#region FUNCTION OC PENDING //
    bWithoutFooter() {
      return this.aOCDetail.filter((e) => e === this.$route.name).length > 0;
    },
    isOPPendingPage() {
      return this.$route.name === "OPPendingDetail";
    },
    getRawMaterials() {
      {
        DB.get(`${URI}/api/sp/v1/raw-materials`, {
          params: {
            // iPageNumber: this.iCurrentPage,
            // iItemsPerPage: this.iItemsPerPage,
            // sSearch: this.sSearch,
            // sSort: this.textExistente,
            // sInOut: this.textVariante,
          },
          headers: {
            Authorization: "Bearer " + this.$store.state.sToken,
          },
        })
          .then((response) => {
            this.$emit("setRawMaterials", response.data.results);
            this.itemSearchRawMaterial = response.data.results;
            this.itemSearchRawMaterial.unshift({
              sName: "Seleccionar materias primas...",
              sRawMaterialId: null,
              disabled: true,
            });
            this.$store.commit("refresher", false);
          })
          .catch((error) => {
            this.bLoadingTable = false;
            this.Error(error.response.data);
          });
      }
    },

    add() {
      if (this.bActive) {
        let oItem = {
          active: true,
          oRawMaterial: this.setRawMaterial(
            this.itemSearchRawMaterial.filter(
              (e) => e.sRawMaterialId === this.sValueRawMaterial
            )
          ),
          textTitleActionConfirm: "Agregar materia prima",
          textDescriptionActionConfirm:
            "El agregar una materia prima a una operación es una acción irreversible.",
          textQuestionActionConfirm: `Acepto la responsabilidad y deseo proseguir con el agregado de una materia a la operación: ${this.oItem.sReference} `,
          returnToViewActionConfirm: true,
          apiActionConfirm: `${URI}/api/sp/v1/orders/general/${this.$route.params.idOP}/items`,
          iTypeAction: 1, //1= Post , 2= put , 3= patch , 4=delete , 5 = get
          oParams: {
            sRawMaterialId: this.sValueRawMaterial,
          },
        };
        this.$emit("setDialogConfirmationGlobal", oItem);
        this.sValueRawMaterial = null;
      } else {
        this.bActive = !this.bActive;
        this.validateForm();
      }
    },
    setRawMaterial(aRawMaterial) {
      return aRawMaterial.map((e, i) => {
        return {
          ...e,
          dNumTransation: i + 1,
          sColorKeyRawMaterial: e.sColorKey,
          sCodeRawMaterial: e.sCode,
          sNameRawMaterial: e.sName,
          dWeightRawMaterial: null ? e.dNetWeight : 0,
          sMeasurementUnitRawMaterial: e.oMeasurementUnit.sMeasurementUnitName,
          sManagerRawMaterial: this.$store.state.sFullname,
        };
      });
    },
    addNewRawMaterial() {
      var contentBtnAdd = document.getElementById(
        "ContentBtnAddRawMaterialOCDetail"
      );
      var contentInput = document.getElementById(
        "ContentInputAddRawMaterialOCDetail"
      );
      if (this.bActive) {
        contentBtnAdd.classList.add(
          "content-btn-add-raw-material-oc-detail-active"
        );
        contentInput.classList.add("content-input-search-raw-material-active");
      } else {
        contentBtnAdd.classList.remove(
          "content-btn-add-raw-material-oc-detail-active"
        );
        contentInput.classList.remove(
          "content-input-search-raw-material-active"
        );
      }
    },

    validateForm() {
      this.bAddRawMaterial = this.bActive && this.sValueRawMaterial === null;
    },
    //#endregion FUNCTION OC PENDING //

    //#region FUNCTION OF FOOTER TABLE //
    changeTable(e) {
      this.iItemsPerPage = e;
      this.iCurrentPage = 1;
      this.$emit("changeTable", {
        iItemsPerPage: this.iItemsPerPage,
        iCurrentPage: this.iCurrentPage,
      });
    },
    backPage() {
      if (this.iCurrentPage > 1) {
        this.iCurrentPage--;
      }
    },
    nextPage() {
      if (this.iCurrentPage < this.numPages) {
        this.iCurrentPage++;
      }
    },
    firstPage() {
      this.iCurrentPage = 1;
    },
    lastPage() {
      this.iCurrentPage = this.numPages;
    },
    //#endregion FUNCTION OF FOOTER TABLE //
  },
  computed: {
    iCurrentPageGlobal() {
      return this.$store.state.iCurrentPageGlobal;
    },
    iItemsPerPageGlobal() {
      return this.$store.state.iItemsPerPageGlobal;
    },
    bAddRawMaterials() {
      return (
        this.aAddRawMaterials.filter((e) => e === this.$route.name).length > 0
      );
    },
    bShowOPPending() {
      return this.$store.state.bShowOPPending;
    },
    bAdminOPPending() {
      return this.$store.state.bAdminOPPending;
    },
  },
  watch: {
    iCurrentPage() {
      this.$emit("changeTable", {
        iItemsPerPage: this.iItemsPerPage,
        iCurrentPage: this.iCurrentPage,
      });
    },
    bChangeSelectedItem() {
      this.selectedItem = 0;
      this.iItemsPerPage = 30;
    },
    bCloseBtnRawMaterial() {
      if (this.sValueRawMaterial === null) {
        this.bActive = !this.bActive;
      }
    },
    bActive() {
      this.addNewRawMaterial();
    },
    sValueRawMaterial() {
      this.validateForm();
    },
    bInitialCurrentPage() {
      this.iCurrentPage = 1;

      // if (
      //   this.iCurrentPageGlobal > 1 &&
      //   this.iCurrentPageGlobal !== this.iCurrentPage
      // ) {
      //   this.iCurrentPage = this.iCurrentPageGlobal;
      // } else {
      //   this.iCurrentPage = 1;

      // }
    },
  },
};
</script>

<style>
.content-add-raw-material-oc-detail {
  display: flex;
  position: relative;
  height: 85px;
  transition: 1s;
  padding: 15px;
  border-top: 1px solid var(--primary-color-border-input) !important;
}

.content-btn-add-raw-material-oc-detail {
  position: absolute;
  transition: 0.5s;
  left: 0;
  margin-left: 15px;
  width: 150px;
}

.content-btn-add-raw-material-oc-detail-active {
  position: absolute;
  width: 150px;
  left: 100%;
  margin-left: 0px;
  margin-right: 25px;
  transform: translate(-110%, 0);
}

.content-input-search-raw-material {
  width: 340px;
  opacity: 0;
  transition: 0.5s;
}

.content-input-search-raw-material-active {
  width: 340px;
  transition: 0.5s;
  opacity: 1;
}

/**************************MODO RESPONSIVO ********************/
/* XS */
@media (max-width: 599px) {
  .demoColorResponsive {
    background-color: rgb(190, 136, 226);
  }

  .content-add-raw-material-oc-detail {
    display: block;
    position: initial;
    height: auto;
    transition: 1s;
    padding: 15px;
    border-top: 0px solid var(--primary-color-border-input) !important;
  }

  .content-btn-add-raw-material-oc-detail {
    position: initial;
    transition: 1s;
    margin-left: 0px;
    width: 100%;
  }

  .content-btn-add-raw-material-oc-detail-active {
    position: initial;
    width: 100%;
    left: 100%;
    transition: 1s;
    margin-left: 0px;
    margin-right: 25px;
    margin-top: 15px;
    transform: translate(0%, 0);
  }

  .content-input-search-raw-material {
    width: 100%;
    opacity: 0;
    height: 0px !important;
    transition: 1s;
  }

  .content-input-search-raw-material-active {
    width: 100%;
    transition: 1s;
    height: 50px !important;
    opacity: 1 !important;
  }
}

/* SM */
@media (min-width: 600px) and (max-width: 959px) {
  .demoColorResponsive {
    background-color: rgb(136, 226, 151);
  }

  /* .content-add-raw-material-oc-detail {
    display: block;
    position: initial;
    height: auto;
    transition: 1s;
    padding: 15px;
    border-top: 0px solid var(--primary-color-border-input) !important;
  }

  .content-btn-add-raw-material-oc-detail {
    position: initial;
    transition: 1s;
    margin-left: 0px;
    width: 100%;
  }
  .content-btn-add-raw-material-oc-detail-active {
    position: initial;
    width: 100%;
    left: 100%;
    transition: 1s;
    margin-left: 0px;
    margin-right: 25px;
    margin-top: 15px;
    transform: translate(0%, 0);
  }

  .content-input-search-raw-material {
    width: 100%;
    opacity: 0;
    height: 0px !important;
    transition: 1s;
  }
  .content-input-search-raw-material-active {
    width: 100%;
    transition: 1s;
    height: 50px !important;
    opacity: 1 !important;
  } */
}

/* MD */
@media (min-width: 960px) and (max-width: 1264px) {
  .demoColorResponsive {
    background-color: rgb(201, 122, 83);
  }
}

/* LG */
@media (min-width: 1264px) and (max-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(204, 198, 109);
  }
}

/* XL */
@media (min-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(113, 199, 201);
  }
}
</style>
